import type { TradingItem } from 'typings/graphql'

import getTradingItemDiscount from './getTradingItemDiscount'


type TradingItemLike = Pick<TradingItem, 'prices'>

const getTradingItemsDiscount = (tradingItems: TradingItemLike[]): number => {
  const discounts = tradingItems?.map(getTradingItemDiscount).filter(Boolean)

  return discounts?.length > 0 ? Math.max(...discounts) : 0
}

export default getTradingItemsDiscount
