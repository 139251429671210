import config from 'config'

const sanitizeRedirectUrl = (redirectUrl: string): string => {
  if (!redirectUrl || typeof redirectUrl !== 'string') {
    return null
  }

  try {
    // Try to parse the URL
    const origin = __CLIENT__ ? window.location.origin : config.publicUrl
    const host = new URL(origin).host

    const url = new URL(redirectUrl, origin)

    // If protocol is not http/https or host doesn't match current domain, reject it
    if (![ 'http:', 'https:' ].includes(url.protocol) || url.host !== host) {
      return null
    }

    // Remove any null bytes, control characters and unicode whitespace
    let sanitized = url.pathname.replace(/[\x00-\x1F\x7F-\x9F\s]/g, '').trim() +
      url.search.replace(/[\x00-\x1F\x7F-\x9F\s]/g, '').trim()

    // remove start slashes
    sanitized = sanitized.replace(/^\/{2,}/, '')
    // Ensure path starts with /
    sanitized = sanitized.startsWith('/') ? sanitized : '/' + sanitized

    // if path contains % in a first part, reject it
    if (sanitized.match(/^\/[^\/?]*?%/)) {
      return null
    }

    return sanitized

  }
  catch (e) {
    // If URL parsing fails, return default path
    return null
  }
}

export default sanitizeRedirectUrl
