import type { Volume } from 'typings/graphql'


const volumes: Record<number, Volume> = {
  20: { unit: 'oz', volume: 0.67 },
  8: { unit: 'oz', volume: 0.27 },
}

const formatVolumeToOz = (volume: Volume): Volume => {
  if (volume.unit === 'ml') {
    return volumes[volume.volume] || volume
  }
  return volume
}

export default formatVolumeToOz
