import logger from 'logger'

import type { Volume, VolumeUnit } from 'typings/graphql'

import formatProductLabelsForAnalytics from './formatProductLabelsForAnalytics'


type ProductLike = Pick<
  ProductFragment.Base,
  | 'id'
  | 'uid'
  | 'type'
  | 'image'
  | 'rebrandImage'
  | 'name'
  | 'brandInfo'
  | 'category'
  | 'gender'
  | 'upchargePrice'
  | 'theEdit'
  | 'isSubscribedToStockNotification'
>

type TradingItem = ProductFragment.Base['tradingItems']['items'][number]

type GetQueueAddItemProductProps = {
  intl: Intl.Manager // for labels
  product: ProductLike
  // force tradingItem selection, we require only some fields
  tradingItem: Pick<TradingItemFragment.Base, 'id' | 'uid' | 'section' | 'isLimitedEdition' | 'volume' | 'productGroup' | 'type' | 'sku' | 'prices'>
  isDrift?: boolean
  isStarterSet?: boolean
  // easy way to override some fields
  override?: Partial<QueueModule.AddItemProduct>
}

// check if volume is exact unit and size
export const isExactVolume = (info: { volume: number, unit: VolumeUnit }, volume: number, unit: VolumeUnit): boolean => {
  return info?.volume === volume && info?.unit === unit
}

export const getIsVial = (tradingItem: Pick<TradingItemFragment.Base, 'productGroup' | 'type'>): boolean => {
  const { productGroup, type } = tradingItem || {}

  return (
    productGroup === 'Perfume'
    // not full-size bottle
    && type !== 'PerfumeBottle'
  )
}

export const getIsFullSizedBottle = (tradingItem: Pick<TradingItemFragment.Base, 'productGroup' | 'type'>): boolean => {
  const { productGroup, type } = tradingItem || {}

  return (
    productGroup === 'Perfume' && type === 'PerfumeBottle'
  )
}

export const getIsTravelSizeVial = (tradingItem: Pick<TradingItemFragment.Base, 'volume' | 'productGroup' | 'type'>): boolean => {
  const { volume } = tradingItem || {}

  return (
    getIsVial(tradingItem)
    && (
      isExactVolume(volume, 0.27, 'oz')
      || isExactVolume(volume, 8, 'ml')
    )
  )
}

export const getIs20mlMiniBottle = (tradingItem: Pick<TradingItemFragment.Base, 'volume' | 'productGroup' | 'type'>): boolean => {
  const { volume, type } = tradingItem || {}

  return (
    getIsVial(tradingItem)
    && (
      isExactVolume(volume, 0.67, 'oz')
      || isExactVolume(volume, 20, 'ml')
    )
    && type === 'ALaCarte'
  )
}

export const getIs1_5mlVial = (tradingItem: Pick<TradingItemFragment.Base, 'volume' | 'productGroup' | 'type'>): boolean => {
  const { volume } = tradingItem || {}

  return getIsVial(tradingItem) && isExactVolume(volume, 1.5, 'ml')
}


// you should pass selected trading item
export const getAddItemProduct = (props: GetQueueAddItemProductProps): QueueModule.AddItemProduct => {
  const {
    intl,
    product,
    tradingItem,
    override,
    isDrift = false,
    isStarterSet = false,
  } = props

  const {
    id,
    uid,
    brandInfo: { name: brand },
    category,
    gender,
    image,
    isSubscribedToStockNotification,
    name,
    rebrandImage,
    theEdit,
    upchargePrice,
  } = product

  const { prices, section, sku } = tradingItem || {}
  const isLimitedDrop = section === 'LimitedDrop'

  const isVial = getIsVial(tradingItem)
  const isTravelSizeVial = getIsTravelSizeVial(tradingItem)
  const is1_5mlVial = getIs1_5mlVial(tradingItem)
  const isRefill = product.type === 'CarFreshenerRefill' && !isStarterSet
  const is20mlMiniBottle = getIs20mlMiniBottle(tradingItem)

  return {
    productId: id,
    productUid: uid,
    tradingItemId: tradingItem?.id,
    tradingItemUid: tradingItem?.uid,
    sku,
    name,
    brand,
    category,
    gender,
    image,
    rebrandImage,
    productLabels: formatProductLabelsForAnalytics({
      product,
      intl,
    }),
    prices,
    upchargePrice,
    isLimitedEdition: tradingItem?.isLimitedEdition,
    isSubscribedToStockNotification,
    theEdit,
    isLimitedDrop,
    isDrift,
    isStarterSet,
    isVial,
    isTravelSizeVial,
    is1_5mlVial,
    is20mlMiniBottle,
    isRefill,
    ...override,
  }
}

type GetRegularEcommerceTradingItemProps = {
  excludeVolumes?: Volume[]
  fullSizeBottleTradingItem?: TradingItem
  productType: ProductFragment.Base['type']
  tradingItems: TradingItem[]
}

const getRegularEcommerceTradingItem = ({ excludeVolumes, fullSizeBottleTradingItem, productType, tradingItems }: GetRegularEcommerceTradingItemProps) => {
  // Perfume cases have all cases as trading items
  if (productType === 'PerfumeCase') {
    return tradingItems.find(({ section }) => {
      return section !== 'Subscription'
    })
  }

  const regularEcommerceTradingItem = tradingItems
    /*
    * We pick ecommerce trading item with largest volume ignoring PerfumeBottle
    * Assign full-size ecommerce trading item if there is no regular one
    * ATTN we shouldn't allow to add course kits directly
    * */
    ?.findLast(({ section, type }) => {
      return section !== 'Subscription' && type !== 'PerfumeBottle'
    }) || fullSizeBottleTradingItem

  const hasExcludedVolume = regularEcommerceTradingItem && excludeVolumes?.some((excludedVolume) => {
    return isExactVolume(regularEcommerceTradingItem.volume, excludedVolume.volume, excludedVolume.unit)
  })

  if (hasExcludedVolume && fullSizeBottleTradingItem) {
    return fullSizeBottleTradingItem
  }

  return regularEcommerceTradingItem
}

type GetTradingItemProps = {
  product: Pick<ProductFragment.Base, 'type' | 'tradingItems'>
  isEcommerce?: boolean // prioritize ecommerce
  isFullSizeBottlePrioritized?: boolean // prefer full bottle
  excludeVolumes?: Volume[]
}

// helps to get required trading item in Product Card
export const getTradingItem = (props: GetTradingItemProps) => {
  const { product, isEcommerce, isFullSizeBottlePrioritized, excludeVolumes } = props

  if (!product) {
    logger.error('getTradingItem was called without a product')

    return {
      tradingItem: null,
      isEcommerceProduct: false,
    }
  }

  const { tradingItems } = product

  const subscriptionTradingItem = tradingItems?.items?.find(({ section }) => section === 'Subscription')

  const fullSizeBottleTradingItem = tradingItems?.items?.findLast(({
    section,
    type,
  }) => section === 'Ecommerce' && type === 'PerfumeBottle')

  const regularEcommerceTradingItem = getRegularEcommerceTradingItem({
    excludeVolumes,
    fullSizeBottleTradingItem,
    productType: product?.type,
    tradingItems: tradingItems?.items,
  })

  /*
  * FullSize bottle has the highest priority.
  * Then depending on isEcommerce it can be ecommerce item or subscription item.
  * For product cards isEcommerce is false
  * */
  const tradingItem = (
    (isFullSizeBottlePrioritized && fullSizeBottleTradingItem)
    || (isEcommerce ? regularEcommerceTradingItem : subscriptionTradingItem || regularEcommerceTradingItem)
  )

  const isEcommerceProduct = tradingItem?.section !== 'Subscription'

  return {
    tradingItem,
    isEcommerceProduct,
  }
}
